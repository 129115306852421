import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { Loader } from 'components/simple';
import { copyToClipboard } from 'simple/logic';
import ModalTemplate from 'common/template/modal-template';

const DirectAPICredentialsModal = (props) => {
    const [information, setInformation] = useState(null);
    const [connectionType, setConnectionType] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const generateButtonClick = (type) => {
        setInformation(false);
        API.get({
            url:
                type === 'indirect'
                    ? apiMethods.API_CHANNEL_CLIENT_GENERATE
                    : apiMethods.DIRECT_API_CLIENTS_GENERATE,
            success: (data) => {
                setInformation(data);
                setConnectionType(type);
            },
            error: () => setInformation(null),
        });
    };

    if (!props.isOpen) return null;

    return (
        <ModalTemplate {...props}>
            <h2>API Credentials</h2>
            {!information ? (
                <>
                    <div style={{ marginBottom: 30, lineHeight: '22px' }}>
                        You can generate new credentials for API direct connection.
                        <br />
                        <div style={{ marginTop: 15 }}>
                            <i className="icon icon-warning" style={{ float: 'left', marginRight: 20 }} />
                            <div style={{ overflow: 'hidden' }}>
                                Once you click this button,{' '}
                                <strong>your previous API credentials will be reset</strong>. Use it only when
                                you don't know your current API login and API password.
                            </div>
                        </div>
                    </div>
                    <button className="button main" onClick={() => generateButtonClick('indirect')}>
                        Get Channel Manager Credentials
                    </button>
                    <div style={{ marginTop: 20 }}>
                        <button className="button" onClick={() => generateButtonClick('direct')}>
                            Get Direct API Credentials
                        </button>
                    </div>
                    {information === false && <Loader page />}
                </>
            ) : (
                <>
                    <p style={{ marginBottom: 30, lineHeight: '22px' }}>
                        New {connectionType === 'indirect' ? 'Channel Manager' : 'Direct API'} Credentials
                        Generated. Please copy them to your configuration files. Also don't forget to replace
                        any older credentials with provided.
                    </p>
                    <p style={{ marginBottom: 15, fontSize: '16px', lineHeight: '24px' }}>
                        <strong>Name:</strong> {information.name}
                    </p>
                    <button
                        className="button"
                        style={{ marginBottom: 35 }}
                        onClick={() => copyToClipboard(information.name)}
                    >
                        Copy Name to Clipboard
                    </button>
                    <p style={{ marginBottom: 15, fontSize: '16px', lineHeight: '24px' }}>
                        <strong>Password:</strong>&nbsp;
                        {passwordVisible ? (
                            information.password
                        ) : (
                            <>
                                ******{' '}
                                <span className="link" onClick={() => setPasswordVisible(true)}>
                                    Show
                                </span>
                            </>
                        )}
                    </p>
                    <button
                        className="button"
                        style={{ marginBottom: 20 }}
                        onClick={() => copyToClipboard(information.password)}
                    >
                        Copy Password to Clipboard
                    </button>
                </>
            )}
        </ModalTemplate>
    );
};

export default DirectAPICredentialsModal;
