import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { searchRoomsCreate } from 'tasks/accommodation/search-rooms-loaders';
import { price } from 'simple';
import { HotelStars } from 'components/accommodation';
import RoomContractSetsList from '../room-contract-sets/room-contract-sets-list';
import { $accommodation } from 'stores';

const AccommodationSearchResultContract = observer(({ contract, setFiltersLastUpdated }) => {
    const navigate = useNavigate();

    const accommodationSelect = () => {
        searchRoomsCreate(contract, navigate);
    };

    return (
        <div className="contract">
            <div className={'summary' + __class(contract.isPreferred, 'preferred')}>
                {contract.isPreferred && <div className="plate">Preferred</div>}
                <div
                    className="photo"
                    onClick={() => accommodationSelect()}
                    style={
                        contract.accommodation.photo.sourceUrl
                            ? { backgroundImage: `url("${contract.accommodation.photo.sourceUrl}")` }
                            : null
                    }
                />
                <div className="detail">
                    <div className="upper">
                        <div className="name-and-price">
                            <div className="name" onClick={() => accommodationSelect()}>
                                <h2>{contract.accommodation.name}</h2>
                                <HotelStars count={contract.accommodation.rating} />
                                <div className="address">{contract.accommodation.location.address}</div>
                            </div>
                            <div className="price">
                                <div>From</div>
                                {price(contract.currency, contract.minPrice)}

                                {typeof contract.totalMarkupPercent === 'number' ? (
                                    <div>Markup: {contract.totalMarkupPercent.toFixed(2)}%</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        {$accommodation.selected?.accommodation?.htId === contract.htId ? (
                            <button
                                className="button small availability"
                                onClick={() => searchRoomsCreate(null)}
                            >
                                Hide options
                            </button>
                        ) : (
                            <button
                                className="button small availability"
                                onClick={() => searchRoomsCreate(contract)}
                            >
                                {'Show more than '}
                                {__plural(
                                    contract.roomContractSetsCount > 2
                                        ? contract.roomContractSetsCount - 1
                                        : contract.roomContractSetsCount,
                                    'available option'
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {$accommodation.selected?.accommodation?.htId === contract.htId && (
                <RoomContractSetsList firstStep setFiltersLastUpdated={setFiltersLastUpdated} />
            )}
        </div>
    );
});

export default AccommodationSearchResultContract;
