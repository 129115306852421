import React from 'react';
import { useParams } from 'react-router-dom';
import content from '../../content/blog.json';
import { date } from 'simple';
import useGoogleAnalytics from '../../hooks/use-google-analytics';

const BlogArticle = () => {
    const { id } = useParams();
    useGoogleAnalytics();

    const data = content.find((item) => item.id === id);

    if (!data) return null;

    return (
        <section>
            <div className="article-page">
                <h1 className="title">{data.title}</h1>
                <span className="text">{date.format.fullDate(data.date)}</span>
                <img className="image" src={data.photo} alt={data.title} />
                <div className="text" dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
        </section>
    );
};

export default BlogArticle;
