import React from 'react';
import { getIn } from 'formik';

const FieldCheckbox = ({ formik, id, label, onChange, value, disabled }) => {
    if (formik) {
        value = Boolean(getIn(formik.values, id));
    }

    const changing = () => {
        if (disabled) {
            return;
        }

        let newValue = !value;

        if (formik) {
            formik.setFieldValue(id, newValue);
        }

        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div onClick={changing} className={'checkbox' + __class(value, 'on') + __class(disabled, 'readonly')}>
            {label}
        </div>
    );
};

export default FieldCheckbox;
