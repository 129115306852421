import React, { useMemo, useState } from 'react';
import { date } from 'simple';
import { MealPlan } from 'components/accommodation';
import { $auth } from 'stores';

const SPECIAL_PROMOTION_NAME = 'Special Promo';
const PROMOTION_NAME = 'Promotion';

const Allotment = ({ room, contract, booking, checkInDate, initiallyExpanded }) => {
    const { remarks } = room;
    const [isExpanded, setExpansion] = useState(initiallyExpanded);
    let deadline = room.deadline || room.deadlineDetails;
    const warnAboutDeadlineIsNear = deadline?.date && date.isPast(date.addDay(deadline.date, -7));
    const contractKind = $auth.agency?.contractKind;

    // temporary solution, todo: remove this logic when backed move promotion from remarks to new field
    const remarksData = useMemo(() => {
        const promotions = [];
        const allRemarks = [];

        remarks?.forEach((item) => {
            const isPromo =
                item?.key?.includes(PROMOTION_NAME) || item?.key?.includes(SPECIAL_PROMOTION_NAME);
            if (isPromo) {
                const promoName = item?.key?.split(':');
                const isSpecialPromo = promoName?.[0] === SPECIAL_PROMOTION_NAME;
                promotions.push({
                    ...item,
                    type: isSpecialPromo ? SPECIAL_PROMOTION_NAME : PROMOTION_NAME,
                    ...(isSpecialPromo && { name: promoName?.[1] }),
                });
                return;
            }
            allRemarks.push(item);
        });

        return {
            remarks: allRemarks,
            promotions,
        };
    }, [remarks]);

    return (
        <ul className="allotment">
            {contract.isNonRefundable ? (
                <li className="warning">
                    <div className="primary">Non-Refundable</div>
                </li>
            ) : date.isFuture(deadline?.date) ? (
                <li className={__class(warnAboutDeadlineIsNear, 'warning', 'important')}>
                    <div className="primary">
                        {deadline?.date
                            ? 'Cancellation Deadline' +
                              ': ' +
                              date.format.deadline(deadline.date, checkInDate)
                            : 'FREE Cancellation - Without Prepayment'}
                    </div>
                    {!!deadline?.date && (
                        <div className="additional">
                            {deadline?.policies?.map((item, index) => (
                                <div key={index}>
                                    From {date.format.a(item.fromDate)} {date.format.time(item.fromDate)}{' '}
                                    cancellation costs {item.percentage}% of total amount.
                                </div>
                            ))}
                            {!date.isPast(deadline.date) &&
                                (!booking ||
                                    ['Confirmed', 'ManualCorrectionNeeded', 'Pending'].includes(
                                        booking.bookingDetails.status
                                    ) ||
                                    'NotPaid' === booking.paymentStatus) &&
                                'VirtualAccountOrCreditCardPayments' === contractKind && (
                                    <div style={{ marginTop: 10 }}>
                                        Important Notice: Payment is due before the deadline date. Please
                                        ensure that your agency account has sufficient funds to avoid auto
                                        cancellation.
                                    </div>
                                )}
                        </div>
                    )}
                </li>
            ) : (
                <li className={__class(warnAboutDeadlineIsNear, 'warning', 'important')}>
                    <div className="primary">{'Within Deadline: ' + date.format.deadline(deadline.date)}</div>
                    <div className="additional">
                        {deadline?.policies?.map((item, index) => (
                            <div key={index}>
                                From {date.format.a(item.fromDate)} {date.format.time(item.fromDate)}{' '}
                                cancellation costs {item.percentage}% of total amount.
                            </div>
                        ))}
                    </div>
                </li>
            )}

            {(__devEnv ||
                __localhost ||
                $auth.activeAgency?.agencyId === 3 ||
                $auth.activeAgency?.agencyId === 503) && (
                <li>
                    {booking?.isGuaranteeRequired === true && (
                        <div className="primary">Payment Guarantee: VCC</div>
                    )}
                </li>
            )}
            <li className="warn">
                <div className="primary">Board Basis</div>
                <div className="additional">
                    <MealPlan room={room} />
                </div>
            </li>
            {booking?.specialRequest ? (
                <li className="important">
                    <div className="primary">Special Request Details</div>
                    <div className="additional">{booking.specialRequest}</div>
                </li>
            ) : null}
            {!!remarksData.promotions.length &&
                remarksData.promotions.map(({ type, name, value }) => (
                    <li className="warn">
                        <div className="promotion">
                            <div>
                                <b>{type}</b>
                            </div>
                            <div>
                                {name && <b>{name}</b>}
                                <p>{value}</p>
                            </div>
                        </div>
                    </li>
                ))}

            {isExpanded &&
                remarksData.remarks?.map((item, index) => (
                    <li key={index}>
                        {!!item.key && <div className="primary">{item.key}</div>}
                        <div className="additional">{item.value}</div>
                    </li>
                ))}
            {Boolean(remarks?.length) && (
                <div
                    className="link"
                    style={{
                        color: '#222',
                        margin: '4px 0 0 24px',
                        fontSize: 14,
                        textDecoration: 'underline',
                    }}
                    onClick={() => setExpansion(!isExpanded)}
                >
                    Show {isExpanded ? 'Less' : 'More'}
                </div>
            )}
        </ul>
    );
};

export default Allotment;
