import { getQueryParams } from 'htcore';
import { windowSessionStorage } from 'htcore/misc/storage';
import { OidcInstance } from 'htcore/auth';

const INVITATION_KEY = '_auth__invCode';

export const setInvite = (code) => windowSessionStorage.set(INVITATION_KEY, code);
export const getInvite = () => windowSessionStorage.get(INVITATION_KEY);
export const forgetInvite = () => windowSessionStorage.remove(INVITATION_KEY);

export const initInvite = () => {
    const params = getQueryParams();

    let invite = getInvite();

    if (params.invCode) {
        setInvite(params.invCode);
        invite = params.invCode;
    }

    if (invite) {
        OidcInstance()
            .getUser()
            .then((auth) => {
                if (!auth?.access_token) {
                    OidcInstance().signinRedirect();
                }
            });
    }

    return invite;
};
