import React from 'react';
import { Link } from 'react-router-dom';
import settings from 'settings';
import { observer } from 'mobx-react';
import { $ui } from 'stores';

const Footer = observer(() => {
    const HappyTravelDotCom = $ui.ourCompanyInfo;

    return (
        <footer>
            <section>
                <div className="company">
                    <div className="logo-wrapper">
                        <a href="/search" className="logo" />
                    </div>
                </div>
                <div className="column">
                    <h3>Information</h3>
                    <ul>
                        <li>
                            <a href="/about-us">About Us</a>
                        </li>
                        <li>
                            <Link to="/terms">Terms & Conditions</Link>
                        </li>
                        <li>
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <h3>Contacts</h3>
                    <ul>
                        <li>
                            <span>Email:</span>{' '}
                            <a href={`mailto:${HappyTravelDotCom.email}`}>{HappyTravelDotCom.email}</a>
                        </li>
                        <li>
                            <span>Recruitment Email:</span>{' '}
                            <a href="mailto:recruitment@happytravel.com">recruitment@happytravel.com</a>
                        </li>
                        <li>
                            <span>Phone:</span> {HappyTravelDotCom.phone}
                        </li>
                        <li>
                            <span>Address:</span> {HappyTravelDotCom.name}
                            <br />
                            {HappyTravelDotCom.address}
                            <br />
                            P.O. {HappyTravelDotCom.postalCode}
                            <br />
                            {HappyTravelDotCom.city}, {HappyTravelDotCom.country}
                        </li>
                        <li>
                            <span>TRN:</span> {HappyTravelDotCom.trn}
                        </li>
                        <li>
                            <span>Trade License:</span> {HappyTravelDotCom.tradeLicense}
                        </li>
                    </ul>
                </div>
            </section>
            <section>
                <div className="payments">
                    <img src="/images/other/mc.png" className="near transparent" alt="Mastercard" />
                    <img
                        src="/images/other/mc-sec.png"
                        className="interval-big transparent"
                        alt="Mastercard Id Check"
                    />
                    <img src="/images/other/visa.png" alt="Visa" />
                    <img src="/images/other/visa-sec.png" className="interval" alt="Visa Secure" />
                    <img src="/images/other/amex.png" alt="American Express" />
                </div>
            </section>
            <section className="copyright">
                <div>
                    All Rights Reserved © 2019 — {new Date().getFullYear()} {HappyTravelDotCom.name}
                </div>
                <div className="service-info column">
                    <span
                        onMouseDown={() => {
                            throw new Error('____  ERRORS test = ' + Date.now());
                        }}
                    >
                        Web – {settings.build || 0}{' '}
                    </span>
                    <span>API – {$ui.currentAPIVersion || 0}</span>
                </div>
            </section>
        </footer>
    );
});

export default Footer;
