import React from 'react';
import { observer } from 'mobx-react';
import AccommodationSearchFilters from './accommodation-filters';
import AccommodationSearchSorters from './accommodation-sorters';

const SearchFilters = observer(({ filterAction, sorterAction, update, secondStep }) => {
    return (
        <div className="filtration">
            <AccommodationSearchFilters update={update} filterAction={filterAction} secondStep={secondStep} />
            <AccommodationSearchSorters sorterAction={sorterAction} />
        </div>
    );
});

export default SearchFilters;
