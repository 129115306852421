import React from 'react';
import { observer } from 'mobx-react';
import { CachedForm, FieldCheckbox, FieldRange, FieldText } from 'components/form';
import { HotelStars } from 'components/accommodation';
import { remapBoardBasis } from 'simple';
import AccommodationFiltersExpandable from './accommodation-filters-expandable';
import { $accommodation, $auth } from 'stores';
import { FilterCheckboxList } from './accommodation-filters-checkbox-list';

const AccommodationFiltersForm = observer(({ filterAction, secondStep }) => {
    const { search, hotelArray, hasAnyFilters } = $accommodation;
    const { filters } = search;
    const selected = $accommodation.selected.filters;

    if (!filters || (!hotelArray?.length && !hasAnyFilters)) return null;

    let throttle;
    const applyHotelNameFilter = (formik) => {
        clearTimeout(throttle);
        throttle = setTimeout(formik.handleSubmit, 500);
    };

    const applyCategoriesFilter = (category) => {
        $accommodation.setSearchSelectedFilters({ ...$accommodation.selected.filters, category });
    };

    return (
        <CachedForm
            onSubmit={filterAction}
            initialValues={selected || {}}
            render={(formik) => (
                <div className="filters form">
                    {!secondStep && (
                        <div style={{ paddingBottom: 10 }}>
                            <h3>Hotel Name</h3>
                            <FieldText
                                formik={formik}
                                id="hotelName"
                                onChange={() => applyHotelNameFilter(formik)}
                                onClear={() => applyHotelNameFilter(formik)}
                                placeholder="Specify Hotel Name"
                                clearable
                            />
                        </div>
                    )}

                    {secondStep && (
                        <div style={{ paddingBottom: 10 }}>
                            <h3>Room Category</h3>
                            <FieldText
                                formik={formik}
                                id="category"
                                onChange={(event) => applyCategoriesFilter(event.target.value)}
                                onClear={() => applyCategoriesFilter(null)}
                                placeholder="Specify the Room Category"
                                clearable
                            />
                        </div>
                    )}

                    {Boolean(filters.ratings?.length) && !secondStep && (
                        <AccommodationFiltersExpandable title="Hotel Stars" id="stars">
                            {filters.ratings.map((item) => (
                                <div key={item}>
                                    <FieldCheckbox
                                        formik={formik}
                                        label={<HotelStars count={item} />}
                                        id={'ratings.' + item}
                                        onChange={formik.handleSubmit}
                                    />
                                </div>
                            ))}
                        </AccommodationFiltersExpandable>
                    )}
                    {filters.chains?.length > 0 && !secondStep && (
                        <AccommodationFiltersExpandable title="Chains" id="chains">
                            <FilterCheckboxList filter={filters.chains} idPrefix={'chain.'} formik={formik} />
                        </AccommodationFiltersExpandable>
                    )}
                    {filters.districts?.length > 1 && !secondStep && (
                        <AccommodationFiltersExpandable title="Districts" id="districts">
                            <FilterCheckboxList
                                filter={filters.districts}
                                idPrefix={'district.'}
                                formik={formik}
                            />
                        </AccommodationFiltersExpandable>
                    )}
                    <div className="price-range">
                        <h3>Price per Night</h3>
                        <h4>Drag the slider to choose minimum and maximum price per night</h4>
                        <FieldRange
                            formik={formik}
                            min={filters.price.min}
                            max={filters.price.max}
                            currency={filters.price.currency}
                            id="price"
                            onChange={formik.handleSubmit}
                        />
                    </div>
                    {Boolean(filters?.boardBasis?.length) && (
                        <AccommodationFiltersExpandable
                            title="Board Basis"
                            id="boardBasis"
                            isStatic={secondStep}
                        >
                            {filters.boardBasis.map((item) => (
                                <div key={item}>
                                    <FieldCheckbox
                                        formik={formik}
                                        label={remapBoardBasis(item)}
                                        id={'boardBasis.' + item}
                                        onChange={formik.handleSubmit}
                                    />
                                </div>
                            ))}
                        </AccommodationFiltersExpandable>
                    )}
                    {Boolean(Object.keys($auth?.agencySettings?.supplierSettings || {}).length) && (
                        <AccommodationFiltersExpandable title="Data Supplier" id="supplier">
                            {Object.keys($auth.agencySettings.supplierSettings).map((item) => (
                                <div key={item}>
                                    <FieldCheckbox
                                        formik={formik}
                                        label={item}
                                        id={'supplier.' + item}
                                        onChange={formik.handleSubmit}
                                    />
                                </div>
                            ))}
                        </AccommodationFiltersExpandable>
                    )}
                </div>
            )}
        />
    );
});

export default AccommodationFiltersForm;
