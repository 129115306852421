import React from 'react';
import { useTitle } from 'htcore';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Consent from '../../components/consent';
import MainBlock from './main';
import TechnologyBlock from './technology-block';
import useGoogleAnalytics from '../../hooks/use-google-analytics';

const HomePage = () => {
    useTitle('');
    useGoogleAnalytics();

    return (
        <div className="lg">
            <div className="lg-container">
                <Header />
            </div>

            <MainBlock />

            <div className="lg-container">
                <TechnologyBlock />
            </div>

            <Consent />
            <Footer />
        </div>
    );
};

export default HomePage;
