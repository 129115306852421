import React from 'react';
import { Link } from 'react-router-dom';

const BANNERS = [
    '/images/landing/footer/big-image/1.png',
    '/images/landing/footer/big-image/2.png',
    '/images/landing/footer/big-image/3.png',
];

let banner;

const Footer = () => {
    if (!banner) {
        banner = BANNERS[Math.trunc(Math.random() * (BANNERS.length - 0.01))];
    }

    return (
        <footer className="lg-footer">
            <div className="lg-container">
                <section>
                    <img className="logo hide-desktop" src="/images/landing/logo.png" alt="logo" />
                    <div className="links-wrapper">
                        <div className="links-group">
                            <Link to="/" className="link">
                                Home
                            </Link>
                            <Link to="/about-us" className="link">
                                About us
                            </Link>
                            <Link to="/product" className="link">
                                Our Product
                            </Link>
                            <Link to="/blog" className="link hide-mobile">
                                Blog
                            </Link>
                            <Link to="/signup" className="link">
                                Join Us
                            </Link>
                        </div>

                        <img className="image" src={banner} alt="" />

                        <div className="links-group">
                            <span className="hide-laptop">
                                © 2024, HappyTravelDotCom Travel and Tourism LLC
                            </span>
                            <div className="links-group">
                                <Link to="/blog" className="link hide-desktop">
                                    Blog
                                </Link>
                                <Link to="/terms" className="link">
                                    Terms &amp; conditions
                                </Link>
                                <Link to="/privacy" className="link">
                                    Privacy Policy
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="info-wrapper">
                        <div className="info">
                            <div>
                                <p>
                                    Address:{' '}
                                    <span>
                                        HappyTravelDotCom Travel and Tourism LLCB105-106, Saraya Avenue
                                        building P.O. Box 36690 Dubai, United Arab Emirates
                                    </span>
                                </p>
                            </div>

                            <div>
                                <p>
                                    Email:{' '}
                                    <a className="underline contact-link" href="mailto:info@happytravel.com">
                                        info@happytravel.com
                                    </a>
                                </p>
                                <p>
                                    Recruitment Email:{' '}
                                    <a
                                        className="underline contact-link"
                                        href="mailto:recruitment@happytravel.com"
                                    >
                                        recruitment@happytravel.com
                                    </a>
                                </p>
                            </div>
                            <div>
                                <p>
                                    Phone:{' '}
                                    <a className="underline contact-link" href="tel:+971-4-2940007">
                                        +971-4-2940007
                                    </a>
                                </p>
                                <p>
                                    TRN: <span>100497287100003</span>
                                </p>
                                <p>
                                    Trade License: <span>828719</span>
                                </p>
                            </div>

                            <div className="icons-group">
                                <span className="icon icon-mastercard" />
                                <span className="icon icon-mastercard-id" />
                                <span className="icon icon-visa" />
                                <span className="icon icon-visa-secure" />
                                <span className="icon icon-amex" />
                            </div>
                        </div>
                        <span className="hide-laptop-desktop">
                            © 2024, HappyTravelDotCom Travel and Tourism LLC
                        </span>
                    </div>
                </section>
            </div>
        </footer>
    );
};

export default Footer;
