import React from 'react';
import { NavLink } from 'react-router-dom';
import { $auth } from 'stores';

const CabinetNavigation = ({ accounts }) => (
    <div className="cabinet-main-navigation">
        <section>
            <NavLink to="/settings/agent">Personal</NavLink>
            <NavLink to="/settings/agency">Agency</NavLink>
            {$auth.permitted('ObservePaymentHistory') && Boolean(accounts?.length) && (
                <NavLink to="/settings/account">Account Statement</NavLink>
            )}
            {$auth.permitted('ApiConnectionManagement') && (
                <NavLink to="/settings/api">API Connection</NavLink>
            )}
        </section>
    </div>
);

export default CabinetNavigation;
