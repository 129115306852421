import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { atLeastOne } from 'tasks/utils/accommodation-filtering';
import AccommodationFiltersDropdown from './accommodation-filters-dropdown';
import { remapBoardBasis, useDropdown, price } from 'simple';
import { HOTEL_STARS } from 'enum';
import { $accommodation } from 'stores';

const generateLabelByList = (list) => {
    if (!list) {
        return 'None';
    }
    const enabledCount = Object.values(list).filter(Boolean).length;
    if (enabledCount === 1) {
        return Object.keys(list).find((key) => list[key]);
    }
    return enabledCount;
};

const AccommodationSearchFilters = observer(({ filterAction, update, secondStep }) => {
    const { selected } = $accommodation;
    const { filters } = selected;
    const refElement = useRef(null);
    const refDropdown = useRef(null);
    const [dropdownOpen, toggleDropdown] = useDropdown(refElement, refDropdown);

    const removeFilter = (item) => {
        const newFilters = JSON.parse(JSON.stringify(filters));
        delete newFilters[item];
        filterAction(newFilters);
        update();
    };

    return (
        <>
            <div className="item" ref={refElement}>
                <div className="button leading-icon button-toggle-filters" onClick={toggleDropdown}>
                    <span className="icon icon-filters" /> Filters
                </div>

                <div ref={refDropdown} hidden={!dropdownOpen}>
                    <AccommodationFiltersDropdown
                        close={() => toggleDropdown(false)}
                        filterAction={filterAction}
                        secondStep={secondStep}
                    />
                </div>
            </div>
            {!!filters && (
                <>
                    {!secondStep && 'hotelName' in filters && (
                        <div className="item dynamically-hidden">
                            <div className="button after-icon" onClick={() => removeFilter('hotelName')}>
                                <span style={{ textTransform: 'capitalize' }}>{filters.hotelName}</span>
                                <span className="icon icon-remove" />
                            </div>
                        </div>
                    )}
                    {secondStep && filters?.category && (
                        <div className="item dynamically-hidden">
                            <div className="button after-icon" onClick={() => removeFilter('category')}>
                                <span style={{ textTransform: 'capitalize' }}>{filters.category}</span>
                                <span className="icon icon-remove" />
                            </div>
                        </div>
                    )}
                    {!secondStep && atLeastOne(filters.district) && (
                        <div className="item dynamically-hidden">
                            <div className="button after-icon" onClick={() => removeFilter('district')}>
                                Districts: {generateLabelByList(filters.district)}
                                <span className="icon icon-remove" />
                            </div>
                        </div>
                    )}
                    {!secondStep && atLeastOne(filters.chain) && (
                        <div className="item dynamically-hidden">
                            <div className="button after-icon" onClick={() => removeFilter('chain')}>
                                Chains: {generateLabelByList(filters.chain)}
                                <span className="icon icon-remove" />
                            </div>
                        </div>
                    )}
                    {!secondStep && 'ratings' in filters && atLeastOne(filters.ratings) && (
                        <div className="item dynamically-hidden">
                            <div className="button after-icon" onClick={() => removeFilter('ratings')}>
                                {HOTEL_STARS.map((val, index) => (filters.ratings[val] ? index : 0))
                                    .filter((v) => v)
                                    .join(', ')}{' '}
                                Stars
                                <span className="icon icon-remove" />
                            </div>
                        </div>
                    )}
                    {'price' in filters && (
                        <div className="item dynamically-hidden">
                            <div className="button after-icon" onClick={() => removeFilter('price')}>
                                {filters.price.max >= 3000 && 'From'}{' '}
                                {price($accommodation.search.filters.price.currency, filters.price.min)}
                                {filters.price.max < 3000 &&
                                    ` – ${price(
                                        $accommodation.search.filters.price.currency,
                                        filters.price.max
                                    )}`}
                                <span className="icon icon-remove" />
                            </div>
                        </div>
                    )}
                    {'boardBasis' in filters && atLeastOne(filters.boardBasis) && (
                        <div className="item dynamically-hidden">
                            <div className="button after-icon" onClick={() => removeFilter('boardBasis')}>
                                {(() => {
                                    const bb = Object.keys(filters.boardBasis)
                                        .map((val) => (filters.boardBasis[val] ? val : 0))
                                        .filter((v) => v);
                                    if (bb.length === 1) return remapBoardBasis(bb[0]);
                                    return 'Board Basis' + ': ' + bb.length;
                                })()}
                                <span className="icon icon-remove" />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
});

export default AccommodationSearchFilters;
