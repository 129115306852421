import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { date, PassengersCount } from 'simple';
import { $accommodation } from 'stores';

const RequestSummaryBillet = observer(() => {
    const { search } = $accommodation;
    return (
        <div className="billet sticky hide-mobile">
            <h2>You were looking for</h2>
            <div className="line">
                <span className="icon icon-summary-location" />
                {search.request.destination}
            </div>
            <div className="line">
                <span className="icon icon-summary-calendar" />
                {date.format.day(date.day(search.request.checkInDate))} {' – '}
                {date.format.day(date.day(search.request.checkOutDate))}
            </div>
            <div className="line">
                <span className="icon icon-summary-guests" />
                <PassengersCount
                    adults={search.request.adultsTotal}
                    children={search.request.childrenTotal}
                />
                {', '}
                {__plural(search.numberOfNights, 'Night')}
            </div>
            {!search.request?.htIds?.[0].includes('Accommodation_') && (
                <Link to="/search/results" className="button">
                    View Other Options
                </Link>
            )}
        </div>
    );
});

export default RequestSummaryBillet;
