import React from 'react';
import TextBlock from '../../components/text-block';
import content from '../../content/team.json';

const TeamBlock = () => {
    const [openedCards, setOpenedCards] = React.useState([]);

    const onIconClick = (id) => {
        setOpenedCards((prevState) =>
            prevState.includes(id) ? prevState.filter((item) => item !== id) : [...prevState, id]
        );
    };

    const getIsGrayBackground = (index) => index % 3 === 0;

    return (
        <section className="team-block">
            <TextBlock
                title={
                    <>
                        Meet our <span>travel gurus</span>
                    </>
                }
                text="Our team, with five years of unity and over 400 years of collective industry expertise, excels in offering travel solutions faster and more reliably than others, ensuring unparalleled experiences for every client."
            />

            <div className="team-wrapper">
                {content.map(({ name, position, photo, description }, index) => (
                    <div key={name} className="card">
                        <div
                            className={`description ${
                                openedCards.includes(name) ? 'opened-card-description' : ''
                            } ${getIsGrayBackground(index) ? 'gray-description' : ''}`}
                        >
                            {photo && <img className="photo" src={photo} alt={position} />}
                            {photo && (
                                <div onClick={() => onIconClick(name)} className=" icon-wrapper">
                                    <span className="icon icon-minus" />
                                    <span className="icon icon-minus icon-plus" />
                                </div>
                            )}
                            <p>{description}</p>
                        </div>
                        <div className="text-wrapper">
                            <p className="name">{name}</p>
                            <p className="position">{position}</p>
                        </div>
                    </div>
                ))}

                <a href="mailto:recruitment@happytravel.com" className="card">
                    <div className="description link-card">
                        <div className="arrow-wrapper">
                            <div className="circle-arrow">
                                <span className="icon icon-arrow" />
                            </div>
                        </div>
                        <div className="link-card-text-holder">
                            <h2>Let’s work together</h2>
                            <p>Explore our open roles</p>
                        </div>
                    </div>
                    <div className="text-wrapper" />
                </a>
            </div>
        </section>
    );
};

export default TeamBlock;
