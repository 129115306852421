import React, { useMemo } from 'react';
import FieldDestination from 'components/complex/field-destination';
import { FieldDatepicker, FieldText } from 'components/form';
import PeopleDropdown from 'components/complex/room-details-dropdown';
import { countPassengers } from 'simple/logic';
import FieldCountry from 'components/complex/field-country';
import { $notifications } from 'stores';

const SearchFormFullsizePart = ({ formik }) => {
    const adults = countPassengers(formik.values, 'adultsNumber');
    const children = countPassengers(formik.values, 'childrenNumber');
    const rooms = formik.values.roomDetails.length;

    const nights = useMemo(() => {
        const { checkInDate, checkOutDate } = formik.values;
        if (checkInDate && checkOutDate) {
            const diff = Math.abs(new Date(checkOutDate) - new Date(checkInDate));
            return Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        return 0;
    }, [formik.values]);

    const searchButtonClick = () => {
        if (!formik.values.destination) {
            $notifications.addNotification(
                'Please choose a hotel or a destination to start the search',
                null,
                'information'
            );
        } else if (!formik.values.nationality || !formik.values.residency) {
            $notifications.addNotification(
                'Please select guests nationality and residency to start the search',
                null,
                'information'
            );
        }
    };

    return (
        <div className="form fullsize">
            <div className="row">
                <FieldDestination
                    formik={formik}
                    id="destination"
                    label="Destination, Hotel Name, Location or Landmark"
                    placeholder="Destination or Hotel Name"
                />
                <FieldDatepicker
                    formik={formik}
                    id="dates"
                    first="checkInDate"
                    second="checkOutDate"
                    label={
                        <>
                            Dates •{' '}
                            <b style={{ color: '#231f20', fontWeight: 600 }}>{__plural(nights, 'Night')}</b>
                        </>
                    }
                    placeholder="Dates"
                    className="size-one"
                    future
                />
                <FieldText
                    formik={formik}
                    id="room"
                    label="Guests • Rooms"
                    placeholder="Choose options"
                    className="size-one"
                    Dropdown={PeopleDropdown}
                    Icon={<span className="icon icon-search-guests" />}
                    value={[
                        __plural(adults, 'Adult'),
                        ...(children ? [children + (children === 1 ? ' Child' : ' Children')] : []),
                        __plural(rooms, 'Room'),
                    ].join(' • ')}
                />
            </div>
            <div className="row">
                <FieldCountry
                    formik={formik}
                    id="nationality"
                    anotherField="residency"
                    label="Nationality"
                    placeholder="Choose your nationality"
                    className="size-two"
                    clearable
                />
                <FieldCountry
                    formik={formik}
                    id="residency"
                    anotherField="nationality"
                    label="Residency"
                    placeholder="Choose your residency"
                    className="size-two"
                    clearable
                />
                <div className="field">
                    <div className="inner button-holder">
                        <button type="submit" className="main button" onClick={searchButtonClick}>
                            Search Accommodations
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchFormFullsizePart;
