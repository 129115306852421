import React from 'react';
import { observer } from 'mobx-react';
import { BOOKING_STATUS } from 'htcore/enum';
import { date, price } from 'simple';
import { Dual } from 'components/simple';
import { MealPlan } from 'components/accommodation';
import { $payment } from 'stores';
import { PAYMENT_METHODS } from 'enum';

const BookingSummary = observer(
    ({
        isService,
        details,
        contract,
        checkInDate,
        checkOutDate,
        numberOfNights,
        numberOfGuests,
        fromHistory,
    }) => {
        const rooms = contract.rooms || details.roomDetails || [];
        const photo = details.photo?.sourceUrl || details.accommodationInfo?.photo?.sourceUrl;

        const markup = contract.totalMarkupPercent || contract.rate?.totalMarkupPercent;

        return (
            <>
                <div className="subtitle">
                    {details.location?.locality ? details.location.locality + ', ' : ''}
                    {details.location.address}
                </div>
                <h4 className="title">{details.name || details.accommodationName}</h4>
                {!!details?.contactInfo?.phones?.length && (
                    <div className="subtitle">Phone: {details.contactInfo.phones.join(', ')}</div>
                )}
                {contract.isDirectContract && (
                    <div className="tag">
                        <strong>Direct Connectivity</strong>
                    </div>
                )}
                {contract.isPackageRate && (
                    <div className="tag">
                        <strong>Package Rate</strong>
                    </div>
                )}
                {!!photo && (
                    <div className="photo-holder">
                        <div className="photo" style={{ backgroundImage: `url("${photo}")` }} />
                    </div>
                )}

                <h4>Reservation Summary</h4>
                {contract.surrogateAgencyName && (
                    <Dual a="Surrogate Agency" b={<i>{contract.surrogateAgencyName}</i>} />
                )}
                {contract.supplier && (
                    <Dual
                        a="Supplier"
                        b={
                            <i>
                                {contract.supplier +
                                    (contract.subSupplier ? ` (${contract.subSupplier})` : '')}
                            </i>
                        }
                    />
                )}
                <Dual a="Arrival Date" b={date.format.a(date.day(checkInDate))} />
                <Dual a="Departure Date" b={date.format.a(date.day(checkOutDate))} />
                <Dual a="Rooms" b={__plural(rooms.length, 'Room')} />
                <Dual a="Nights" b={__plural(numberOfNights || details.numberOfNights, 'Night')} />
                <Dual a="Guests" b={__plural(numberOfGuests || details.numberOfPassengers, 'Guest')} />
                {Boolean(details.nationality) && (
                    <Dual a="Nationality & Residency" b={details.nationality + ' / ' + details.residence} />
                )}
                {isService ? (
                    <Dual a="Service Type" b={details.serviceName} />
                ) : (
                    <Dual a="Board Basis" b={<MealPlan room={rooms[0]} allRooms={rooms} />} />
                )}

                <h4>Room & Total Cost</h4>
                {!isService &&
                    rooms?.map((room, i) => (
                        <React.Fragment key={'trc' + i}>
                            <div
                                style={
                                    room.cancelled || details.status === BOOKING_STATUS.Cancelled
                                        ? { textDecoration: 'line-through' }
                                        : {}
                                }
                            >
                                <Dual
                                    a={'Room ' + (room._index > 1 ? room._index : '') + ' Cost'}
                                    b={
                                        fromHistory
                                            ? contract.paymentMethod === PAYMENT_METHODS.CARD &&
                                              Boolean(room.creditCardPrice?.amount)
                                                ? price(room.creditCardPrice)
                                                : price(room.price)
                                            : $payment.paymentMethod === PAYMENT_METHODS.CARD
                                            ? price(room.rate?.creditCardPrice)
                                            : price(room.rate?.finalPrice)
                                    }
                                />
                            </div>
                        </React.Fragment>
                    ))}

                {isService &&
                    rooms?.map((room, i) => (
                        <React.Fragment key={'trc' + i}>
                            <div
                                style={
                                    details.status === BOOKING_STATUS.Cancelled
                                        ? { textDecoration: 'line-through' }
                                        : {}
                                }
                            >
                                <Dual
                                    a={'Service ' + (room._index > 1 ? room._index : '') + ' Cost'}
                                    b={
                                        contract.paymentMethod === PAYMENT_METHODS.CARD
                                            ? price(
                                                  room.creditCardPrice.currency,
                                                  room.creditCardPrice.amount
                                              )
                                            : price(contract.totalPrice.currency, room.price)
                                    }
                                />
                            </div>
                        </React.Fragment>
                    ))}

                {Boolean(
                    details.status === BOOKING_STATUS.Cancelled || contract.appliedCancellationFee?.amount
                ) && (
                    <Dual
                        a="Cancellation Fee"
                        b={
                            contract.appliedCancellationFee
                                ? price(contract.appliedCancellationFee)
                                : 'Not Provided'
                        }
                    />
                )}

                {isService ? (
                    <Dual a="Total Service Cost" b={price(contract.totalPrice)} className="total-price" />
                ) : (
                    <Dual
                        a="Total Cost"
                        b={
                            <>
                                {details.status === BOOKING_STATUS.Cancelled ||
                                    (rooms.some((room) => room.awaitingCancellation || room.cancelled) && (
                                        <>
                                            <span style={{ color: '#666' }}>
                                                {rooms.every(
                                                    (room) => room.price.currency === rooms[0].price.currency
                                                )
                                                    ? price(
                                                          rooms[0].price.currency,
                                                          rooms.reduce(
                                                              (sum, room) => sum + room.price.amount,
                                                              0
                                                          )
                                                      )
                                                    : price(contract.totalPrice)}
                                            </span>
                                            {' → '}
                                        </>
                                    ))}
                                {details.status === BOOKING_STATUS.Cancelled &&
                                Boolean(contract.appliedCancellationFee)
                                    ? price(contract.appliedCancellationFee)
                                    : fromHistory
                                    ? price(contract.totalPrice)
                                    : $payment.paymentMethod === PAYMENT_METHODS.CARD
                                    ? price(contract.rate.creditCardPrice)
                                    : price(contract.rate.netPrice)}
                            </>
                        }
                        className="total-price"
                    />
                )}

                {contract.supplierPrice ? (
                    <Dual a="NPtS (Original)" b={price(contract.supplierPrice)} />
                ) : null}

                {typeof markup === 'number' ? <Dual a="Markup %" b={`${markup.toFixed(2)}%`} /> : null}
            </>
        );
    }
);

export default BookingSummary;
