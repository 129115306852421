[
    {
        "question": "How can I become a partner of HappyTravel.com?",
        "answer": "To explore partnership opportunities with HappyTravel.com, please visit our website and click the \"Join Us\" button to initiate your registration process. Once you have submitted your application, a dedicated account manager will contact you to proceed with the enrollment. We are open to collaboration with both WEB-based and API-integrated travel agencies, with contract terms tailored to individual circumstances."
    },
    {
        "question": "How quickly can I receive a booking confirmation?",
        "answer": "Our state-of-the-art booking system guarantees instant online confirmation, typically within a mere 60 seconds after your booking request."
    },
    {
        "question": "Which destinations are currently supported?",
        "answer": "HappyTravel.com proudly offers a diverse portfolio of direct hotel partnerships across the globe, with a focus on regions such as the United Arab Emirates, Indian Ocean, Europe, and the USA. Our direct contracts include over 5,000 properties in the UAE and more than 4,000 properties in destinations like the Maldives, Egypt, Turkey, and other parts of the Indian Ocean. We also maintain connections with major hotel chains, including Marriott, Four Seasons, Mandarin Oriental, Jumeirah International, Kerzner, Accor, Hilton, and Best Western, totaling approximately 25,000 properties. In addition, we collaborate with XML suppliers and have special agreements with local Destination Management Companies (DMCs), enhancing our offerings with over 500,000 options worldwide."
    },
    {
        "question": "Are all additional charges, such as taxes and resort fees, included in the rate?",
        "answer": "The rates we provide incorporate all government-mandated taxes as specified by hotels or suppliers. However, there may be additional destination-specific taxes or fees which guests are required to settle directly with the hotel. Moreover, hotels may request a credit card or cash deposit at check-in as a security guarantee for potential incidental expenses, which will be refunded upon check-out. Please note, HappyTravel.com is not liable for any such additional expenses incurred by guests."
    },
    {
        "question": "Where can I find my reservation?",
        "answer": "Your reservation details are readily accessible in your back office system and have also been sent to the email address linked with your account for your convenience."
    },
    {
        "question": "Does HappyTravel.com offer ecotourism or sustainable travel options?",
        "answer": "We are staunch advocates for responsible tourism at HappyTravel.com. Our curated selection of ecotourism offerings ensures that your travels not only enrich your life but also contribute positively to the preservation of our planet and the prosperity of local communities. Explore our sustainable travel options that allow for adventure with a gentle footprint, aligning your travel experiences with your values."
    },
    {
        "question": "Does HappyTravel.com offer a loyalty program for travel agents, and what are the benefits?",
        "answer": "Indeed, at HappyTravel.com, we recognize and reward the invaluable partnerships we have with travel agents. Our Loyalty Program is designed to express our gratitude, offering agents exclusive benefits for their continued collaboration. As a member, you gain access to priority service, enhanced commission rates, and special agent-only deals."
    }
]
