import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { useDropdown } from 'simple';
import FieldSelectDropdown from 'components/form/field-select/field-select-dropdown';
import { $accommodation } from 'stores';

const AccommodationSearchSorters = observer(({ sorterAction }) => {
    const { selected } = $accommodation;
    const { sorter } = selected;
    const refElement = useRef(null);
    const refDropdown = useRef(null);
    const [dropdownOpen, toggleDropdown] = useDropdown(refElement, refDropdown);

    return (
        <div className="item">
            <div
                className={'button' + __class(sorter?.price, 'after-icon')}
                onClick={() => toggleDropdown(true)}
                ref={refElement}
            >
                {sorter?.price ? 'Price' : 'Sort by'}
                {!!sorter?.price && (
                    <span className={'icon' + __class(sorter.price === -1, 'icon-up', 'icon-down')} />
                )}
            </div>

            {dropdownOpen && (
                <div ref={refDropdown}>
                    <FieldSelectDropdown
                        options={[
                            { text: 'Not Sorted', value: {} },
                            {
                                text: (
                                    <>
                                        Price <em>(High to Low)</em>
                                    </>
                                ),
                                value: { price: 1 },
                            },
                            {
                                text: (
                                    <>
                                        Price <em>(Low to High)</em>
                                    </>
                                ),
                                value: { price: -1 },
                            },
                        ]}
                        setValue={(option) => {
                            toggleDropdown(false);
                            sorterAction(option.value);
                        }}
                    />
                </div>
            )}
        </div>
    );
});

export default AccommodationSearchSorters;
