import React from 'react';
import TextBlock from '../../components/text-block';

const JoinBlock = () => (
    <section id="join-us" className="join-block block-paddings">
        <TextBlock
            title="It all started with a love for travel. What began as a journey of passion has grown into a pioneering travel technology company, reshaping the art of exploration."
            text="Joining Happy Travel makes achieving your dreams easier, offering paths for growth, travel, and living happily. With us, your aspirations are within reach, turning every journey into a joy."
        >
            <img src="/images/landing/about-us/conference.jpg" alt="Happy Travel team" />
        </TextBlock>
    </section>
);

export default JoinBlock;
