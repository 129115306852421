import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { GroupRoomTypesAndCount, price, date } from 'simple';
import { copyToClipboard } from 'simple/logic';
import { MealPlan } from 'components/accommodation';
import Deadline from 'components/deadline';
import { DailyRatesContract } from 'components/simple';
import { $accommodation } from 'stores';

const RoomSummary = observer(({ htId, roomContractSet, onSelect, accommodationName }) => {
    const [loadedDeadline, setLoadedDeadline] = useState(null);
    const [isDailyRatesVisible, setDailyRatesVisibility] = useState(null);

    const copy = (e) => {
        e.stopPropagation();

        const deadline = loadedDeadline?.date || roomContractSet.deadline.date;
        let deadlineString = '';

        if (roomContractSet.deadline.isFinal || loadedDeadline) {
            if (!deadline) deadlineString = 'FREE Cancellation - Without Prepayment';
            else if (date.isFuture(deadline)) deadlineString = 'Deadline' + ': ' + date.format.dots(deadline);
            else deadlineString = 'Within deadline';
            deadlineString += ', ';
        }

        copyToClipboard(
            [
                date.format.dots(date.day($accommodation.search.request.checkInDate)) +
                    ' – ' +
                    date.format.dots(date.day($accommodation.search.request.checkOutDate)),
                accommodationName,
                GroupRoomTypesAndCount({ contracts: roomContractSet.rooms, text: true }),
                $accommodation.search.request.adultsTotal +
                    'A' +
                    ($accommodation.search.request.childrenTotal
                        ? ', ' + $accommodation.search.request.childrenTotal + 'C'
                        : ''),
                roomContractSet.rooms[0].mealPlan,
                deadlineString + price(roomContractSet.rate.finalPrice).trim(),
            ].join(', ')
        );
    };

    return (
        <>
            <div className="room" onClick={onSelect}>
                <div className="info">
                    <h3>
                        <div>
                            <GroupRoomTypesAndCount contracts={roomContractSet.rooms} />
                        </div>
                        <span onClick={copy} className="copier" title="Copy to Clipboard">
                            <span className="icon icon-copy" />
                        </span>
                    </h3>
                    {roomContractSet.supplier && (
                        <div>
                            <i>
                                Supplier:{' '}
                                {roomContractSet.supplier +
                                    (roomContractSet.subSupplier ? ` (${roomContractSet.subSupplier})` : '')}
                            </i>
                        </div>
                    )}
                    <div>
                        {!roomContractSet.isNonRefundable ? (
                            <Deadline
                                searchId={$accommodation.search.id}
                                htId={htId}
                                roomContractSet={roomContractSet}
                                loadedDeadline={loadedDeadline}
                                setLoadedDeadline={setLoadedDeadline}
                                checkInDate={$accommodation.search.request.checkInDate}
                            />
                        ) : (
                            <div className="tag">
                                <i className="icon icon-warning" />
                                Within deadline
                            </div>
                        )}
                        {Boolean(
                            roomContractSet.rooms.find((item) => item.isAvailableImmediately === false)
                        ) && (
                            <div className="tag">
                                <b>On Request</b>
                            </div>
                        )}
                        {roomContractSet.isNonRefundable && <div className="tag">Non-Refundable</div>}
                        {roomContractSet.isDirectContract && <div className="tag">Direct Connectivity</div>}
                        {roomContractSet.rooms[0]?.boardBasis && (
                            <div className="tag">
                                <MealPlan
                                    short
                                    room={roomContractSet.rooms[0]}
                                    allRooms={roomContractSet.rooms}
                                />
                            </div>
                        )}
                        {roomContractSet.isPackageRate && <div className="tag">Package Rate</div>}
                        {roomContractSet.rooms[0]?.isDynamic === true && (
                            <div className="tag">Dynamic Offer</div>
                        )}
                    </div>
                </div>
                <div className="price">
                    {price(roomContractSet.rate.finalPrice)}
                    {roomContractSet.rate.totalMarkupPercent !== null ? (
                        <div className="note">
                            Markup: {roomContractSet.rate.totalMarkupPercent?.toFixed(2)}%
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="button-holder">
                    <div className="button-wrapper">
                        <button className="button small main" onClick={onSelect}>
                            <i className="icon icon-arrow-next" />
                        </button>
                    </div>
                    {Boolean(roomContractSet.rooms[0]?.dailyRoomRates?.length) && (
                        <div className="daily-rates-button-holder">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDailyRatesVisibility((v) => !v);
                                }}
                            >
                                Show Daily Rates
                                <span
                                    className={
                                        'icon icon-big-expand' + (isDailyRatesVisible ? ' expanded' : '')
                                    }
                                />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {isDailyRatesVisible && <DailyRatesContract rooms={roomContractSet.rooms} />}
        </>
    );
});

export default RoomSummary;
