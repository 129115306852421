import React, { useState } from 'react';
import DirectAPICredentialsModal from './direct-api-credentials-modal';
import { $auth } from 'stores';

const DirectAPIClient = () => {
    const [modalVisibility, setModalVisibility] = useState(false);
    const [key, setKey] = useState(null);

    if (!$auth.permitted('ApiConnectionManagement')) return null;

    return (
        <div>
            <h2>API Connection</h2>
            <button
                className="button"
                onClick={() => {
                    setModalVisibility(true);
                    setKey(Number(new Date()));
                }}
            >
                Get API Connection Credentials
            </button>
            <DirectAPICredentialsModal
                key={key}
                isOpen={modalVisibility}
                onClose={() => setModalVisibility(false)}
            />
        </div>
    );
};

export default DirectAPIClient;
