import React, { useMemo } from 'react';

const Pager = ({ pageCount, currentPage, action }) => {
    if (pageCount < 2) {
        return null;
    }

    const pages = useMemo(() => {
        const result = [];
        if (currentPage > 0) {
            result.push('<');
        }
        if (currentPage > 2) {
            result.push(0);
        }
        if (currentPage > 3) {
            result.push('...');
        }
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            if (i >= 0 && i < pageCount) {
                result.push(i);
            }
        }
        if (currentPage + 3 < pageCount) {
            result.push('...');
        }
        if (currentPage < pageCount - 1) {
            result.push('>');
        }

        return result;
    }, [pageCount, currentPage]);

    return (
        <div className="pager">
            {pages.map((value) => {
                if (value === '...') {
                    return <div className="points">...</div>;
                }
                if (value === '<') {
                    return (
                        <div className="button small" onClick={() => action(currentPage - 1)}>
                            <i className="icon icon-arrow-expand rotate-left" />
                        </div>
                    );
                }
                if (value === '>') {
                    return (
                        <div className="button small" onClick={() => action(currentPage + 1)}>
                            <i className="icon icon-arrow-expand rotate-right" />
                        </div>
                    );
                }
                return (
                    <div
                        className={'button small' + __class(value === currentPage, 'active')}
                        onClick={() => action(value)}
                    >
                        {value + 1}
                    </div>
                );
            })}
        </div>
    );
};

export default Pager;
