import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { SEARCH_STATUSES } from 'enum';
import { $accommodation } from 'stores';
import { generateFiltersObject, generateSortersObject } from '../utils/accommodation-filtering';

const POLLING_DURATION = 10 * 60 * 1000;
const REQUEST_INTERVAL = 1000;
let throttle = false;

const roomsLoad = (after) => {
    const { search, selected } = $accommodation;
    const { filters, sorter } = selected;
    if (!$accommodation.selected.accommodation?.roomContractSets?.length) {
        $accommodation.setLoadingRooms(true);
    }
    API.get({
        url: apiMethods.A_SEARCH_TWO_RESULT(search.id, selected.accommodation.htId),
        body: {
            ...generateFiltersObject(filters),
            ...generateSortersObject(sorter),
        },
        success: (result) => {
            $accommodation.selectSearchResultAccommodation(selected.accommodation, result);
        },
        error: () => {
            $accommodation.setRoomsTaskState(SEARCH_STATUSES.BROKEN, 0);
        },
        after: () => {
            $accommodation.setLoadingRooms(false);
            if (after) {
                after();
            }
        },
    });
};

const requestRooms = () => {
    const { search } = $accommodation;

    if (
        throttle ||
        (SEARCH_STATUSES.isFinished(search.roomsTaskState) &&
            search.roomsResultCount <= $accommodation.selected.accommodation?.roomContractSets?.length)
    ) {
        return;
    }
    throttle = true;

    roomsLoad(() => {
        throttle = false;
    });
};

const subscribeSearchRoomsResults = () =>
    setInterval(() => {
        const { search, selected } = $accommodation;

        if (!search.id || !selected?.accommodation?.htId) return;

        if (
            !SEARCH_STATUSES.isPending(search.roomsTaskState) &&
            search.roomsResultCount <= $accommodation.selected.accommodation?.roomContractSets?.length
        )
            return;

        if (search.roomsLastCheckedAt - search.roomsCreatedAt > POLLING_DURATION) {
            $accommodation.setRoomsTaskState(SEARCH_STATUSES.TIMEOUT, 0);
            return;
        }

        API.get({
            url: apiMethods.A_SEARCH_TWO_CHECK(search.id, selected.accommodation.htId),
            ignoreErrors: true,
            success: ({ taskState, resultCount }) => {
                if (SEARCH_STATUSES.isReadyToLoad(taskState)) {
                    requestRooms();
                }
                $accommodation.setRoomsTaskState(taskState, resultCount);
            },
            error: () => {
                $accommodation.setRoomsTaskState(SEARCH_STATUSES.BROKEN, 0);
            },
        });
    }, REQUEST_INTERVAL);

const searchRoomsCreate = (contract, navigate) => {
    $accommodation.selectSearchResultAccommodation(contract?.accommodation);
    if (contract) {
        API.get({
            url: apiMethods.ACCOMMODATION_DETAILS($accommodation.search.id, contract.htId),
            success: (result) => $accommodation.setSelectedAccommodationFullDetails(result),
        });
    }
    if (navigate) {
        navigate('/search/contract', { replace: true });
    }
};

const searchSecondStepLoadWithNewFilters = (values) => {
    $accommodation.setSearchSelectedFilters(values);
    roomsLoad();
};

const searchSecondStepLoadWithNewOrder = (values) => {
    $accommodation.setSearchSelectedSorter(values);
    roomsLoad();
};

export {
    subscribeSearchRoomsResults,
    searchRoomsCreate,
    searchSecondStepLoadWithNewFilters,
    searchSecondStepLoadWithNewOrder,
};
