'use strict';

module.exports = {
  number: require('./src/card-number'),
  expirationDate: require('./src/expiration-date'),
  expirationMonth: require('./src/expiration-month'),
  expirationYear: require('./src/expiration-year'),
  cvv: require('./src/cvv'),
  postalCode: require('./src/postal-code'),
  creditCardType: require('credit-card-type')
};
