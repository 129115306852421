import React from 'react';
import { Link } from 'react-router-dom';

const TailorMadeBlock = () => (
    <section className="tailor-made-block">
        <div className="content">
            <h2>Tailor-made trips and direct hotel bargains await</h2>
            <p className="text">
                Secure tailored travel itineraries and exclusive hotel offers for your clients
            </p>
            <Link to="/signup" className="learn-more-button">
                <button className="lg-button lg-button-dark">Request Contact</button>
            </Link>
        </div>
    </section>
);

export default TailorMadeBlock;
