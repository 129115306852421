import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { API } from 'htcore';
import { SEARCH_STATUSES } from 'enum';
import apiMethods from 'common/api-methods';
import { scroll, subscribeScroller } from 'common/misc/scroller';
import { Loader } from 'components/simple';
import RoomSummary from '../parts/room-summary';
import {
    searchSecondStepLoadWithNewFilters,
    subscribeSearchRoomsResults,
} from 'tasks/accommodation/search-rooms-loaders';
import { $accommodation, $notifications } from 'stores';

const RoomContractSetsList = observer(({ setFiltersLastUpdated }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { hasAnyFilters, selected } = $accommodation;
    const { filters } = selected;
    const selectedAccommodation = $accommodation.selected.accommodation;
    const { roomsTaskState, isLoadingRooms } = $accommodation.search;

    useEffect(() => {
        const interval = subscribeSearchRoomsResults();
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(subscribeScroller, []);

    const clearFilters = () => {
        searchSecondStepLoadWithNewFilters(null);
        setFiltersLastUpdated(Number(new Date()));
    };

    //todo: move to booking logic block
    const roomContractSetSelect = (roomContractSet) => {
        setLoading(true);
        API.get({
            url: apiMethods.A_SEARCH_STEP_THREE(
                $accommodation.search.id,
                selectedAccommodation.htId,
                roomContractSet.id
            ),
            success: (result) => {
                if (!result?.availabilityId) {
                    $notifications.addNotification('Sorry, this room is not available now, try again later');
                    return;
                }
                $accommodation.selectRoomContractSet(result, roomContractSet);
                navigate('/accommodation/booking');
            },
            after: () => setLoading(false),
        });
    };

    const roomContractSets = useMemo(() => {
        if (filters?.category) {
            return selectedAccommodation?.roomContractSets?.filter(
                (item) =>
                    !!item.rooms.find((room) =>
                        room.contractName.toLowerCase().includes(filters.category.toLowerCase())
                    )
            );
        }

        return selectedAccommodation?.roomContractSets;
    }, [filters, selectedAccommodation]);

    return (
        <div className="rooms">
            {(loading || isLoadingRooms || !roomContractSets?.length) && <Loader page={loading} />}
            {!isLoadingRooms &&
                roomContractSets?.map((roomContractSet, index) => (
                    <RoomSummary
                        key={index}
                        roomContractSet={roomContractSet}
                        onSelect={() => roomContractSetSelect(roomContractSet)}
                        htId={selectedAccommodation.htId}
                        accommodationName={selectedAccommodation.name}
                    />
                ))}

            {SEARCH_STATUSES.isFinished(roomsTaskState) && !roomContractSets?.length && (
                <>
                    {!isLoadingRooms && hasAnyFilters && (
                        <div className="error-page inside">
                            <div>
                                <div className="text">
                                    <h1>No rooms match selected filters</h1>
                                    <p>Try to set less strict conditions</p>
                                </div>

                                <button className="button" onClick={clearFilters}>
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                    )}
                    {!isLoadingRooms && !hasAnyFilters && (
                        <div className="error-page inside">
                            <div>
                                <div className="text">
                                    <h1>No rooms available</h1>
                                    <p>
                                        Unfortunately, we have not found any rooms available, please try again
                                    </p>
                                </div>

                                <Link className="button" to="/search/results">
                                    View Other Options
                                </Link>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

export default RoomContractSetsList;
