import React from 'react';
import { useTitle } from 'htcore';
import DirectAPIClientPagePart from './direct-api-client';

const ApiConnection = () => {
    useTitle('API Connection');

    return (
        <div className="cabinet block">
            <section>
                <DirectAPIClientPagePart />
            </section>
        </div>
    );
};

export default ApiConnection;
