import React from 'react';
import { Flag } from 'components/simple';
import { useNavigate } from 'react-router-dom';
import { searchSaveForm } from 'tasks/accommodation/search-create';
import { $notifications, $ui } from 'stores';
import { FORM_NAMES } from '../form';

const TileItem = ({ item }) => {
    const navigate = useNavigate();

    const startSearch = () => {
        if (__devEnv) {
            $notifications.addNotification(
                'Please test index page links on the production environment',
                'Only on Production',
                'warning'
            );
            return;
        }

        const values = {
            ...JSON.parse($ui.formCache[FORM_NAMES.SearchForm]),
            destinationInput: item.destinationName,
            destination: item.htId,
        };
        $ui.formCache[FORM_NAMES.SearchForm] = JSON.stringify(values);
        searchSaveForm(values, () => null, navigate);
    };

    return (
        <div
            className={'item' + __class(item.exclusive, 'offer')}
            style={{ backgroundImage: `url("${item.image}")` }}
            onClick={startSearch}
        >
            <div className="body">
                <div className="info">
                    <Flag code={item.flag} />
                    <div>
                        <div className="title">{item.city || item.title}</div>
                        {item.propertiesCount && (
                            <div className="count">More than {item.propertiesCount} properties</div>
                        )}
                    </div>
                </div>
                {item.minPrice && (
                    <div className="price">
                        <span>From</span> USD {item.minPrice}
                    </div>
                )}
            </div>
            <div className="bottom" />
        </div>
    );
};

export default TileItem;
