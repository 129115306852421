import React from 'react';
import Tiles from 'components/tiles';
import Search from 'pages/accommodation/search-form/search-form';
import { AuthKeeperComponent } from 'htcore/auth/pages';
import { $auth } from 'stores';
import PageTemplate from 'common/template/page-template';
import { observer } from 'mobx-react';
import ItineraryNotification from './itinerary-notification';

const AccommodationTitlePage = observer(({ noSearch }) => (
    <>
        <ItineraryNotification />
        {!noSearch && (
            <div className="search-fullsize-wrapper">
                <Search fullsize key="search-full" />
            </div>
        )}
        <div className="tiles block">
            <section>
                <h2>Countries & Hotels</h2>
                <Tiles
                    list={[
                        {
                            city: 'PARIS, FRANCE',
                            flag: 'fr',
                            propertiesCount: '2831',
                            image: '/images/hotels/france.jpg',
                            destinationName: 'Paris, France',
                            htId: 'Locality_305496',
                        },
                        {
                            city: 'LONDON, ENGLAND',
                            flag: 'gb',
                            propertiesCount: '3399',
                            image: '/images/hotels/london.jpg',
                            destinationName: 'London, The United Kingdom',
                            htId: 'Locality_305665',
                        },
                    ]}
                />
                <Tiles
                    list={[
                        {
                            city: 'ROME, ITALY',
                            flag: 'it',
                            propertiesCount: '5965',
                            image: '/images/hotels/rome.jpg',
                            destinationName: 'Rome, Italy',
                            htId: 'Locality_306382',
                        },
                        {
                            city: 'BARCELONA, SPAIN',
                            flag: 'es',
                            propertiesCount: '1770',
                            image: '/images/hotels/barcelona.jpg',
                            destinationName: 'Barcelona, Spain',
                            htId: 'Locality_305900',
                        },
                        {
                            city: 'DUBAI, United Arab Emirates',
                            flag: 'ae',
                            propertiesCount: '949',
                            image: '/images/hotels/dubai.jpg',
                            destinationName: 'Dubai, The United Arab Emirates',
                            htId: 'Locality_306217',
                        },
                    ]}
                />
                <h2>Exclusive offers</h2>
                <Tiles
                    list={[
                        {
                            title: 'EMERALD PALACE KEMPINSKI DUBAI, DUBAI',
                            flag: 'ae',
                            image: '/images/hotels/emeraldplace.jpg',
                            destinationName:
                                'Kempinski Hotel And Residences Palm Jumeirah, Palm Jumeirah, The United Arab Emirates',
                            htId: 'Accommodation_27015236',
                        },
                        {
                            title: 'HILTON BAKU, BAKU',
                            flag: 'az',
                            image: '/images/hotels/hilton.jpg',
                            destinationName: 'Hilton Baku, Baku, Azerbaijan',
                            htId: 'Accommodation_27021464',
                        },
                        {
                            title: 'KEMPINSKI HOTEL MALL OF THE EMIRATES, DUBAI',
                            flag: 'ae',
                            image: '/images/hotels/kempinski.jpg',
                            destinationName:
                                'Kempinski Hotel Mall Of The Emirates, Dubai, The United Arab Emirates',
                            htId: 'Accommodation_27015193',
                        },
                        {
                            title: 'PULLMAN DUBAI DOWNTOWN, DUBAI',
                            flag: 'ae',
                            image: '/images/hotels/pullman.jpg',
                            destinationName: 'Pullman Dubai Downtown, Dubai, The United Arab Emirates',
                            htId: 'Accommodation_27005834',
                        },
                    ]}
                />
            </section>
        </div>
    </>
));

export default AccommodationTitlePage;
