import React from 'react';
import { Link } from 'react-router-dom';
import content from '../../content/questions.json';

const QuestionsBlock = () => {
    const [openedQuestion, setOpenedQuestion] = React.useState();

    const onIconClick = (question) =>
        setOpenedQuestion((prevState) => (prevState === question ? null : question));

    return (
        <section className="block-paddings questions-block">
            <h2>Have questions?</h2>
            <div className="content">
                <div className="questions-list">
                    {content.map(({ question, answer }) => (
                        <div
                            key={question}
                            onClick={() => onIconClick(question)}
                            className="question-card"
                            data-opened={openedQuestion === question}
                        >
                            <div className="question">
                                <p>{question}</p>
                                <div className=" icon-wrapper">
                                    <span className="icon icon-minus" />
                                    <span className="icon icon-minus icon-plus" />
                                </div>
                            </div>
                            <p className="text">{answer}</p>
                        </div>
                    ))}
                </div>
                <div className="image-block">
                    <div className="image-block-content">
                        <h3>Start on your path to B2B success</h3>
                        <Link to="/signup">
                            <button className="lg-button lg-button-primary">Request contact</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default QuestionsBlock;
