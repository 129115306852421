import React from 'react';
import { useTitle } from 'htcore';
import Header from '../../components/header';
import Consent from '../../components/consent';
import Footer from '../../components/footer';
import OurStoryBlock from './our-story-block';
import JoinBlock from './join-block';
import TeamBlock from './team-block';
import OpenPositionsBlock from './open-positions-block';
import useGoogleAnalytics from '../../hooks/use-google-analytics';

const AboutUsPage = () => {
    useTitle('About Us');
    useGoogleAnalytics();

    return (
        <div className="lg">
            <div className="lg-container">
                <Header />
                <OurStoryBlock />
                <JoinBlock />
                <TeamBlock />
                <OpenPositionsBlock />
            </div>

            <Consent />
            <Footer />
        </div>
    );
};

export default AboutUsPage;
