import React from 'react';
import { useTitle } from 'htcore';
import Header from '../../components/header';
import Consent from '../../components/consent';
import Footer from '../../components/footer';
import PartnershipBlock from './partnership-block';
import PlatformBlock from './platform-block';
import OpinionBlock from './opinion-block';
import TailorMadeBlock from './tailor-made-block';
import QuestionsBlock from './questions-block';
import ServicesBlock from './services-block';
import useGoogleAnalytics from '../../hooks/use-google-analytics';

const ProductPage = () => {
    useTitle('Product');
    useGoogleAnalytics();

    return (
        <div className="lg">
            <div className="lg-container">
                <Header />
                <PartnershipBlock />
                <PlatformBlock />
                <ServicesBlock />
                <OpinionBlock />
                <TailorMadeBlock />
                <QuestionsBlock />
            </div>

            <Consent />
            <Footer />
        </div>
    );
};

export default ProductPage;
