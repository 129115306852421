import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Header = () => {
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);

    const onClickMenu = () => {
        document.body.style.overflowY = !open ? 'hidden' : 'auto';
        setOpen((prevState) => !prevState);
    };

    const linksGroup = (
        <>
            <div className="link-wrapper" data-active-link={pathname.includes('product')}>
                <span className="icon icon-plane" />
                <Link to="/product" className="link">
                    Our Product
                </Link>
            </div>
            <div className="link-wrapper" data-active-link={pathname.includes('about-us')}>
                <span className="icon icon-plane" />
                <Link to="/about-us" className="link">
                    About us
                </Link>
            </div>
            <div className="link-wrapper" data-active-link={pathname.includes('blog')}>
                <span className="icon icon-plane" />
                <Link to="/blog" className="link">
                    Blog
                </Link>
            </div>
        </>
    );

    const buttonsGroup = (
        <>
            <Link to="/signup">
                <button className="lg-button lg-button-primary">Join Us</button>
            </Link>
            <Link to="/login">
                <button className="lg-button lg-button-secondary">Sign In</button>
            </Link>
        </>
    );

    return (
        <section>
            <header className="lg-header">
                <div className={open ? 'mobile-menu mobile-menu-open' : 'mobile-menu'}>
                    <div className="mobile-links hide-laptop-desktop">
                        <div className="hide-laptop-desktop link-wrapper ">
                            <span className="icon icon-plane" />
                            <Link to="/" className="link">
                                Home
                            </Link>
                        </div>
                        {linksGroup}
                    </div>
                    <div className="mobile-buttons-group hide-laptop-desktop">{buttonsGroup}</div>
                </div>
                <nav>
                    {open ? (
                        <div className="icon icon-cross hide-laptop-desktop" onClick={onClickMenu} />
                    ) : (
                        <div className="icon icon-burger-menu hide-laptop-desktop" onClick={onClickMenu} />
                    )}
                    <div className="links-group">{linksGroup}</div>

                    <Link to="/">
                        <img className="logo" src="/images/landing/logo.png" alt="Happytravel.com" />
                    </Link>

                    {!open && (
                        <>
                            <div className="buttons-group">{buttonsGroup}</div>
                            <Link to="/login" className="link join-us-link underline">
                                Sign In
                            </Link>
                        </>
                    )}
                </nav>
            </header>
        </section>
    );
};

export default Header;
