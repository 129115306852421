import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { date } from 'simple';

const Deadline = ({ roomContractSet, searchId, htId, loadedDeadline, setLoadedDeadline, checkInDate }) => {
    const deadline = loadedDeadline?.date || roomContractSet.deadline.date;
    const isRequestPossible = !loadedDeadline;

    const request = (event) => {
        event.stopPropagation();
        API.get({
            url: apiMethods.REQUEST_DEADLINE(searchId, htId, roomContractSet.id),
            success: (data) => {
                setLoadedDeadline(data || {});
            },
        });
    };

    if (isRequestPossible && roomContractSet.deadline?.isFinal !== true)
        return (
            <div className="deadline">
                <div className="link tag clickable" onClick={request}>
                    Show Cancellation Deadline <i className="icon icon-info" />
                </div>
            </div>
        );

    if (deadline) {
        if (date.isFuture(deadline))
            return (
                <div className={'tag deadline future' + __class(!isRequestPossible, 'requested')}>
                    Deadline –{' '}
                    <b style={{ fontWeight: 700 }}>{date.format.deadline(deadline, checkInDate)}</b>
                </div>
            );
        return (
            <div className={'tag deadline near' + __class(!isRequestPossible, 'requested')}>
                <i className="icon icon-warning" />
                Within deadline
            </div>
        );
    }

    return <div className="deadline free">FREE Cancellation - Without Prepayment</div>;
};

export default Deadline;
