import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTitle } from 'htcore';
import Header from './header/header';
import Footer from './footer/footer';

const PageTemplate = ({ clear, AlternativeHeader, AlternativeFooter, children, className, title, style }) => {
    useTitle(title);

    const location = useLocation();
    const isSearch = location.pathname === '/search/results';
    if (isSearch) {
        AlternativeFooter = () => null;
    } // todo: temporary footer solution for 1 step of search

    return (
        <>
            {clear ? null : AlternativeHeader ? <AlternativeHeader /> : <Header />}
            <div className="block-wrapper">
                {className ? (
                    <div className={className} style={style}>
                        {children}
                    </div>
                ) : (
                    children
                )}
            </div>
            {clear ? null : AlternativeFooter ? <AlternativeFooter /> : <Footer />}
        </>
    );
};

export default PageTemplate;
